//ClientList.vue

<template>
	<div class="container-fluid">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>Clients</h1>
				<p class="lead text-muted">
					Manage rtNOW Client List.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<a class="btn btn-lg btn-success" v-on:click.prevent="newClient()" href
					><i class="material-icons">&#xE145;</i>Add New</a
				>
			</div>
		</div>

		<pre>clients: {{ clients }}</pre>

		<div class="table-responsive">
			<table class="table -table-bordered">
				<thead>
					<tr>
						<th scope="col" style="text-align: center">#</th>
						<th scope="col">Name</th>
						<th scope="col">Address</th>
						<th scope="col">Fax</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="n in clients" :key="n.id">
						<td style="text-align: center">{{ n.id }}</td>
						<th>{{ n.name }}</th>
						<td>{{ n.bill_city }}, {{ n.bill_state }}</td>
						<td>{{ n.fax }}</td>
						<td align="right" class="text-right">
							<a
								href
								class="btn btn-outline-danger"
								v-on:click.prevent="removeClient(n.id, false)"
								style="margin-right: .25em"
								>Remove</a
							>
							<a
								href
								class="btn btn-outline-primary"
								v-on:click.prevent="editClient(n)"
								>Edit</a
							>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-modal :modal="saveClientModal" data-v-on-closeModal="closeModal">
			<template slot="title">Save Client</template>

			<form
				v-on:submit.prevent="saveClient"
				:class="{ 'was-validated': errors.items.length > 0 && formSubmitted }"
			>
				<div class="form-group">
					<label
						>Client <code>{{ selectedClient.id }}</code></label
					>

					<input
						v-model="selectedClient.name"
						type="text"
						id="facility_name"
						name="Facility Name"
						placeholder="Facility Name"
						class="form-control form-control-lg -is-invalid"
						v-validate="'required'"
						required
					/>

					<!-- <div class="invalid-feedback" v-show="errors.has('Facility Name')">{{ errors.first('Facility Name') }}</div> -->
				</div>

				<div class="form-group">
					<label>Location</label>

					<div class="input-group mb-2">
						<input
							type="text"
							placeholder="Address"
							name="Address"
							v-model="selectedClient.bill_address_1"
							class="form-control form-control-lg"
							v-validate="'required'"
							required
						/>
					</div>

					<div class="input-group">
						<input
							type="text"
							placeholder="City"
							name="City"
							v-model="selectedClient.bill_city"
							class="form-control form-control-lg"
							v-validate="'required'"
							required
						/>

						<select
							class="custom-select form-control-lg"
							id="facility.state"
							name="State"
							v-model="selectedClient.bill_state"
							v-validate="'required'"
							required
						>
							<option value="">- State -</option>
							<option
								v-for="(key, value) in states"
								:value="value"
								:key="value"
								>{{ key }}</option
							>
						</select>

						<input
							type="number"
							placeholder="Zipcode"
							name="Zipcode"
							v-model="selectedClient.bill_zip"
							class="form-control form-control-lg"
							v-validate="'required|min:5'"
							required
						/>
					</div>

					<div
						style="display: block;"
						class="invalid-feedback"
						v-show="errors.has('Zipcode')"
					>
						{{ errors.first("Zipcode") }}
					</div>
				</div>

				<div class="form-group">
					<div class="form-row">
						<label class="col-6">Phone</label>
						<label class="col-6">Fax</label>
					</div>

					<div class="input-group">
						<input
							type="text"
							placeholder="Phone #"
							v-model="selectedClient.phone"
							class="form-control form-control-lg"
						/>

						<input
							type="text"
							name="Fax"
							placeholder="Fax #"
							v-model="selectedClient.fax"
							class="form-control form-control-lg"
							v-validate="'required|min:5'"
							required
						/>

						<div class="invalid-feedback" v-show="errors.has('Fax')">
							{{ errors.first("Fax") }}
						</div>
					</div>
				</div>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="saveClientModal.show = false"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="saveClient"
				>
					Save
				</button>
			</template>
		</app-modal>

		<app-modal :modal="removeClientModal" data-v-on:closeModal="closeModal">
			<template slot="title">Confirm Removal Client</template>

			<form>
				<p>Are you sure you want to permanently remove this Client?</p>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="removeClientModal.show = false"
				>
					Never mind
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="removeClient(removeClientModal.id, true)"
				>
					Remove
				</button>
			</template>
		</app-modal>
	</div>
</template>

<script>
//import FacilityModal from './FacilityModal.vue'

import { mapActions } from "vuex";

export default {
	data() {
		return {
			clients: [],
			states: {},
			selectedClient: {
				id: 58
			},
			showClientModal: false,

			removeClientModal: {
				show: false,
				id: null // the id of the client to remove
			},

			saveClientModal: {
				show: false
			},

			formSubmitted: false
		};
	},

	methods: {
		...mapActions(["ajax", "globalAlert"]),

		newClient() {
			this.saveClientModal.show = true;
			this.selectedClient.id = null;
		},

		editClient(client) {
			this.saveClientModal.show = true;
			this.selectedClient = client;
		},

		saveClient() {
			this.formSubmitted = true;

			this.$validator.validateAll().then(result => {
				if (result) {
					var id = this.selectedClient.id ? this.selectedClient.id : "";

					this.ajax({
						url: "/client/save/" + id,
						data: this.selectedClient,
						success: () => {
							if (id == "") {
								// new
								alert("new client created");
							} else {
								alert("old client saved");
							}
						}
					});
					return;
				}

				this.globalAlert({
					title: "Form Errors",
					body: "Please correct errors."
				});
			}); // validateAll
		}, // saveClient

		removeClient(id, confirmed) {
			if (!confirmed) {
				this.removeClientModal.show = true;
				this.removeClientModal.id = id;
			} else {
				this.removeClientModal.show = false;
				this.removeClientModal.id = null;

				//var tis = this;

				this.ajax({
					url: "/client/delete/" + id,
					data: {},
					success: () => {
						// find the object in this array where event id matches
						var selectedCli = this.clients.find(function(ele) {
							return ele.id == id;
						});

						// find the array index of this object
						var i = this.clients.indexOf(selectedCli);

						// delete the array child at a given index
						this.$delete(this.clients, i);
					}
				});
			}
		},

		refreshClients() {
			this.ajax({
				url: "/client/get",
				data: {},
				success: json => {
					this.clients = json.client;
					this.states = json.states;
				}
			});
		}
	},

	mounted() {
		this.refreshClients();
	},

	components: {}
};
</script>

<style lang="scss"></style>
